<template>
  <div>
    <button v-if='showAddButton'
      @click='addContact'
      class='border px-4 py-3 rounded-md border-transparent text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900'>
      <plus-circle-icon class='inline-block' style='margin-top: -4px;'></plus-circle-icon> Add Contact
    </button>
    <contact-edit v-else 
      :editing-contact='editingContact' 
      :display-in-modal='true'
      @cancel-editing='closeEditControl' 
      @submit-update='submitUpdate' />
    <contact-card v-for='contact in contacts' :key='`contact-${contact.id}`'
                  :contact='contact'
                  @set-edit-contact='editThisContact'
                  @delete-contact='deleteContact'>
    </contact-card>
    <div v-if='hasNoContacts'
      class='text-center my-16'>
      {{drilldownLimitedPartner.limited_partner_name}} has no associated contacts
    </div>
  </div>
</template>

<script>

import { mapActions,
         mapGetters,
         mapState }       from 'vuex'
import { PlusCircleIcon } from '@vue-hero-icons/outline'
import ContactEdit        from '@/components/contacts/ContactEdit.vue'
import ContactCard        from '@/components/contacts/ContactCard.vue'
import cloneDeep   from 'lodash/cloneDeep'


export default {
  name: 'LimitedPartnerContacts',
  components: {
    ContactEdit,
    ContactCard,
    PlusCircleIcon,
  },
  data () {
    return {
      showAddButton: true,
      editingContact: {},
    }
  },
  watch: {
    drilldownLimitedPartnerId: {
      handler: function (newVal) {
        this.getContactsForLimitedPartner(newVal)
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('limitedPartners', [
      'drilldownLimitedPartnerId',
    ]),
    ...mapState('limitedPartners', [
      'drilldownLimitedPartner',
    ]),
    ...mapState('contacts', [
      'contacts',
    ]),
    hasNoContacts () {
      return this.contacts.length === 0
    },
  },
  methods: {
    ...mapActions('contacts', [
      'createContact',
      'deleteContact',
      'getContactsForLimitedPartner',
      'addOrUpdateContact',
    ]),
    addContact () {
      this.editingContact = {}
      this.showAddButton = false
    },
    closeEditControl () {
      this.editingContact = {}
      this.showAddButton = true
    },
    editThisContact (contact) {
      this.editingContact = contact
      this.showAddButton = false
    },
    submitUpdate (contact) {
      let contactDetails = cloneDeep(contact)
      contactDetails.limited_partner_id = this.drilldownLimitedPartnerId
      this.addOrUpdateContact(contactDetails).then(() => {
        this.getContactsForLimitedPartner(this.drilldownLimitedPartnerId)
        this.closeEditControl()
      })
    }
  }
}
</script>

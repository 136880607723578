<template>
  <div class='px-4 py-6 border rounded-md my-4 relative max-w-lg'>
    <h1 class='text-2xl uppercase text-gray-900'>{{contact.entity_name}}</h1>
    <div class='flex flex-row mt-8'>
      <div class='flex-grow flex-shrink-0 w-1/2'>
        <h1 class='text-lg uppercase text-gray-900 font-semibold'>{{contact.contact_name}}</h1>
        <h3 class='text-base text-gray-600 uppercase'>{{contact.title}}</h3>
        <h3 class='text-base text-gray-600'>{{contact.department}}</h3>
      </div>
      <div class='flex-grow flex-shrink-0  w-1/2'>
        <h4 class='mb-1 text-sm text-gray-600'><mail-icon class='inline-block mr-2 text-gray-300 h-5' :stroke-width='1' style='margin-top: -5px;' />{{contact.email}}</h4>
        <h4 class='mb-1 text-sm text-gray-600'><phone-icon class='inline-block mr-2 text-gray-300 h-5' :stroke-width='1' style='margin-top: -5px;' />{{contact.phone_number}}</h4>
        <h4 class='mb-1 text-sm text-gray-600'><location-marker-icon class='inline-block mr-2 text-gray-300 h-5' :stroke-width='1' style='margin-top: -5px;' />{{contact.address}}</h4>
      </div>
    </div>
    <div v-if='editable' class='absolute' style='right: 1.5rem; top: 1.5rem;'>
      <button @click='editThisContact(contact)' class='mr-4'><pencil-alt-icon class='h-4' /></button>
      <button @click='deleteContact(contact.id)' class=''><trash-icon class='h-4' /></button>
    </div>
    <portal to='modals'>
      <contact-edit 
        v-if='showEditContact'
        :display-in-modal='true' />
    </portal>
  </div>
</template>

<script>
import { mapActions,
         mapState }  from 'vuex'
import { LocationMarkerIcon,
         MailIcon,
         PhoneIcon,
         PencilAltIcon,
         TrashIcon } from '@vue-hero-icons/outline'
import ContactEdit   from '@/components/contacts/ContactEdit.vue'

export default {
  name: 'ContactCard',
  components: {
    ContactEdit,
    LocationMarkerIcon,
    MailIcon,
    PencilAltIcon,
    PhoneIcon,
    TrashIcon,
  },
  props: {
    contact: Object,
    editable: Boolean,
  },
  computed: {
    ...mapState('contacts', [
      'editingContact'
    ]),
    showEditContact () {
      return this.editingContact.id === this.contact.id
    }
  },
  methods: {
    ...mapActions('contacts', [
      'deleteContact',
      'editContact',
      'getContactCustomFields',
    ]),
    editThisContact (contact) {
      this.editContact(contact)
      this.getContactCustomFields(contact.id).then(() => {
        this.$store.dispatch('modalOpen')
      })
    },
  }
}
</script>
